import Barlow from "../typography/fonts/Barlow-Regular.ttf";
import BarlowBold from "../typography/fonts/Barlow-Bold.ttf";

const MuiCssBaseline = {
    styleOverrides: 
`
@font-face {
    font-family: "Barlow";
    src: url(${Barlow}) format(truetype);
}
@font-face {
    font-family: "BarlowBold";
    src: url(${BarlowBold}) format(truetype);
}
`

}

export default MuiCssBaseline;