import secureLocalStorage from "react-secure-storage";
import FE_URL from "../constants/urls";
import apiCall from "../services";
import ENDPOINT from "../constants/endpoints";

export default function handleLogOut(navigate, resetUserRecoil) {
    apiCall(ENDPOINT.AUTH_TOKEN_REVOKE)
        .then(() => {
            secureLocalStorage.clear();
            resetUserRecoil();
            navigate(FE_URL.LANDING_PAGE, { replace: true });
        })
        .catch(error => console.error(error));
}
