import React, { lazy } from "react";
import Header from './components/layout/header';
import FE_URL from "./constants/urls";
import PrivateRoute from "./pages/ProtectedRoute";
import { Outlet } from "react-router-dom";

const Error = lazy(() => import('./pages/Error'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));

const Footer = lazy(() => import('./components/layout/Footer'));
const PrivacyPolicyTnC = lazy(() => import('./pages/Footers/PrivacyPolicyTnC'));
const FAQ = lazy(() => import('./pages/Footers/FAQ'));
const Home = lazy(() => import('./pages/Home'));
const Onboarding = lazy(() => import('./pages/onboarding'));
const ManageDependants = lazy(() => import('./pages/dependant/ManageDependants'));
const DependantDetails = lazy(() => import('./pages/dependant/DependantDetails'));
const OnBoardingSuccess = lazy(() => import('./pages/onboarding/Success'));
const ViewMyInsurance = lazy(() => import('./pages/onboarding/ViewMyInsurance'));
const NotificationPreference = lazy(() => import('./pages/onboarding/NotificationPreference'));
const ManageConsent = lazy(() => import('./pages/ManageConsent'));
const CaseDetails = lazy(() => import('./pages/case/CaseDetails'));
const CaseListing = lazy(() => import('./pages/CaseListing'));
const Singpass = lazy(() => import('./pages/Singpass'));
const PayorSchemeRanking = lazy(() => import('./pages/onboarding/PayorSchemeRanking'));
const SyncPolicies = lazy(() => import('./pages/SyncPolicies'));
const PolicyPreferenceSuccess = lazy(() => import('./pages/PolicyPreferenceSuccess'));
const InformationRetrievalChild = lazy(() => import('./pages/onboarding/MyInfoRetrievalChild'));

function Layout() {
  return (
      <div style={{position:"relative", maxHeight:"100vh", height: "100vh", overflowY: "hidden"}}>
        <Header />
            <Outlet />
        <Footer />
      </div>
  );
}

const routeConfig = [
    // landingPage
    {
        path: FE_URL.LANDING_PAGE,
        element: <LandingPage />,
        errorElement: <Error />,
    },
    {
        path: FE_URL.LANDING_PAGE,
        element: <Layout />,
        errorElement: <Error />,
        children: [
            {
                path: `${FE_URL.ONBOARDING}/:dependant?`,
                element: <Onboarding />,
                errorelement: <Error />,
            },
            {
                path: `${FE_URL.HOME}/:dependant?`,
                element: <PrivateRoute><Home /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: FE_URL.MANAGE_CHILDREN,
                element: <PrivateRoute><ManageDependants /></PrivateRoute>,
                errorelement: <Error />,
            },
            {
                path: FE_URL.GETTING_STARTED_CHILDREN,
                element: <PrivateRoute><LoginSignup dependantOnboarding /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.VIEW_INSURANCE_COVERAGE}/:dependant?`,
                element: <PrivateRoute><ViewMyInsurance /></PrivateRoute>,
                errorElement: <Error />,
            },
            // to do pi - 15
            {
                path: `${FE_URL.VIEW_CASES}/:dependant?`,
                element: <PrivateRoute><CaseListing /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: FE_URL.DEPENDENT_DETAILS,
                element: <PrivateRoute><DependantDetails /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.MANAGE_NOTIFICATIONS}/:dependant?`,
                element: <PrivateRoute><NotificationPreference /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.MANAGE_CONSENT}/:dependant?`,
                element: <PrivateRoute><ManageConsent /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.CASE_DETAILS}/:dependant?`,
                element: <PrivateRoute><CaseDetails /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.PAYOR_SCHEME_RANKING}/:dependant?`,
                element: <PrivateRoute><PayorSchemeRanking /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.SYNC_INSURANCE_POLICIES}/:dependant?`,
                element: <PrivateRoute><SyncPolicies /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: `${FE_URL.POLICY_PREFERENCE_SUCCESS}/:dependant?`,
                element: <PrivateRoute><PolicyPreferenceSuccess /></PrivateRoute>,
                errorElement: <Error />,
            },
            {
                path: FE_URL.PRIVACY_POLICY,
                element: <PrivacyPolicyTnC component="privacy-policy" />,
                errorElement: <Error />,
            },
            {
                path: FE_URL.TERMS_AND_CONDITION,
                element: <PrivacyPolicyTnC component="terms-of-use" />,
                errorElement: <Error />,
            },
            { 
                path: FE_URL.FAQ,
                element: <FAQ />,
                errorElement: <Error />,
            },
            {
                path: FE_URL.DEPENDENT_INFO_RETRIEVAL,
                element: <InformationRetrievalChild />,
                errorElement: <Error />,
            },

        ]
    },
    {
        path: FE_URL.GETTING_STARTED,
        element: <>
            <LoginSignup />
            <Footer />
        </>,
        errorElement: <Error />,
    },
    {
        path: FE_URL.SINGPASS_SIGNUP_CALLBACK_URL,
        element: <Singpass />,
        errorElement: <Error />,
    },
    {
        path: `${FE_URL.ONBOARDING_SUCCESS}/:dependant?`,
        element: <PrivateRoute><OnBoardingSuccess /></PrivateRoute>,
        errorelement: <Error />,
    },

];

export default routeConfig;