const MuiAccordionSummary = {
    styleOverrides: {
        content: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": '3',
            "-webkit-box-orient": "vertical",
            [`&.Mui-expanded`]: {
                overflow: "unset",
                textOverflow: "unset",
                display: "-webkit-box",
                "-webkit-line-clamp": 'unset',
                "-webkit-box-orient": "vertical",
            },
        }
    }
}

export default MuiAccordionSummary