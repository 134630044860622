import { BLUE } from "../pallete/colors";

const MuiTabs = {
    styleOverrides: {
        indicator: {
            background: BLUE[800]
        }
    }
};

export default MuiTabs;