import APPLICATION_CONSTANTS from "../constants/constants";
import ENDPOINT from "../constants/endpoints";
import { GetPreloginBucketEndpoint } from "../pages/Config";
import { getS3Object } from "../services/s3";

/**
 * @param setApplicationContext - recoil setRecoilAtom
 * sets application context - prelogin values for application
 */

const getLanguagePack = (setApplicationContext) => {
    const footer = {};
    // call language pack api to get the latest language pack
    getS3Object(`${GetPreloginBucketEndpoint().trim()}${ENDPOINT.PRELOGIN_BUCKET}`)
      .then(({ data }) => {
        for (const [key, value] of Object.entries(data.footer)) {
          footer[key] = value
        }
        Promise.all([
          getS3Object(data.languagePackUrl),
        ]).then(([languagePack]) => {
          setApplicationContext(
            {
              language: APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0).toLowerCase(),
              footer,
              languagePack: languagePack.data.content[APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0).toLowerCase()],
            }
          )
        })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  }

  export default getLanguagePack;