import React, { useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import handleLogOut from '../../utils/handleLogout';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from "recoil";
import { User } from "../../recoil/atoms/User";
import { useErrorBoundary } from "react-error-boundary";
import { ApplicationContext } from '../../recoil/atoms/ApplicationContext';
import APPLICATION_CONSTANTS from '../../constants/constants';
import FE_URL from '../../constants/urls';


// mouse/keyboard events used to identify idleness
const EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
  'focus'
];

export default function IdleTimeout() {

  const [isIdleState, setIsIdleState] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const callbackfn = useCallback(() => { setIsIdleState(true); }, [isIdleState]);
  const navigate = useNavigate();
  const resetUserRecoil = useResetRecoilState(User);
  const { languagePack } = useRecoilValue(ApplicationContext);

  const onPromptFn = () => {
    setIsIdleState(true);
  };
  useIdleTimer({
    events: EVENTS,
    stopOnIdle: true,
    timeout: APPLICATION_CONSTANTS.ACCEPTABLE_IDLE_TIME,
    startOnMount: true,
    startManually: false,
    onIdle: callbackfn,
    onPrompt: onPromptFn

  });

  useEffect(() => {
    if (isIdleState) {
      showBoundary({
        title: languagePack?.[`error.session_timeout.title.label`],
        message: languagePack?.[`error.session_timeout.description.label`],
        ctaText: "Login",
        cta: () => {
          handleLogOut(navigate, resetUserRecoil);
          navigate(FE_URL.GETTING_STARTED);
        }
      });
    }
  }, [isIdleState]);

  return (
    <></>
  );
}
