import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { useTheme } from "@emotion/react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import apiCall from "../../services";
import { FormatDateTimeStamp } from "../../utils/formatDateTime";
import ENDPOINT from "../../constants/endpoints";
import APPLICATION_CONSTANTS from "../../constants/constants";
import FE_URL from "../../constants/urls";

import { ApplicationContext } from "../../recoil/atoms/ApplicationContext";

import CircleIcon from "../../assets/icons/CircleIcon";


const NOTIFICATION_ORDER = "DESC";
const NOTIFICATION_PAGE_SIZE = 10;

export default function Notification({ closePopup }) {
    const [notifications, setNotifications] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [notificationsMeta, setNotificationsMeta] = useState({ hasMore: true });
    const { languagePack } = useRecoilValue(ApplicationContext);
    const notifRef = useRef(null);
    const { palette: { primary, secondary } } = useTheme();
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate();
    const { dependant } = useParams();

    const getNotifications = () => {
        const pageAfter = notificationsMeta.nextCursor ?? null;
        if (notificationsMeta?.hasMore)
            apiCall(
                ENDPOINT.INBOX_MESSAGE_INQUIRY,
                {
                    order: NOTIFICATION_ORDER,
                    pageSize: NOTIFICATION_PAGE_SIZE,
                    pageAfter
                })
                .then(({ data }) => {
                    setNotifications(prev => { return prev.concat(data?.data) ?? prev; });
                    setNotificationsMeta(data.meta ?? { hasMore: true });
                    setShowLoading(false);
                })
                .catch(({ response }) => {
                    showBoundary({
                        ...response
                    });
                });
    };
    useEffect(() => {
        getNotifications();
    }, []);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = notifRef.current;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight;

        if (isNearBottom && notificationsMeta?.hasMore) {
            setShowLoading(true);
            getNotifications();
        }
    };

    const markAsRead = (e, { inboxMessageRefId }) => {
        e.stopPropagation();
        // get the recent notification marked read and shange the isRead status here.
        const notification = notifications.find(n => n.inboxMessageRefId === inboxMessageRefId);
        const notificationIdx = notifications.findIndex(n => n.inboxMessageRefId === inboxMessageRefId);
        notification.isRead = !notification.isRead;
        const _notifications = [...notifications];
        _notifications.splice(notificationIdx, 1, notification);
        setNotifications(_notifications);

        apiCall(
            ENDPOINT.INBOX_MESSAGE_STATUS_UPDATE,
            {
                data: [
                    {
                        inboxMessageRefId,
                        isRead: "true"
                    }
                ]
            }
        )
            .then(() => {
                getNotifications();
            })
            .catch(({ response }) => {
                showBoundary({
                    ...response
                });
            });
    };

    const handleNotificationClick = (notification) => {
        const url = new URL(notification.summaryDeeplinkPath);
        const caseMetadataLinearId = url.searchParams.get("caseMetadataLinearId");
        secureLocalStorage.setItem(APPLICATION_CONSTANTS.CASE_METADATA_LINEAR_ID, caseMetadataLinearId);
        closePopup();
        navigate(dependant ? `${FE_URL.CASE_DETAILS}/${dependant}` : FE_URL.CASE_DETAILS);
    };

    return (
        <Box
            ref={notifRef}
            p={2}
            sx={{ height: "100%", overflowY: "auto" }}
            onScroll={handleScroll}>
            {notifications.map(notification => (
                <Grid
                    container
                    key={notification.inboxMessageRefId}
                    py={1}
                    alignItems="baseline"
                    onClick={() => handleNotificationClick(notification)}>
                    <Grid item xs={12} textAlign="end">
                        {
                            notification?.isRead
                                ?
                                <Typography variant="caption" color="neutral.700">
                                    {languagePack?.["notification.notification.notification_overlay_popup._read.label"]}
                                </Typography>
                                :

                                <Typography
                                    variant="caption"
                                    color="blue.800"
                                    onClick={(e) => markAsRead(e, notification)}>
                                    {languagePack?.["notification.notification.notification_overlay_popup.mark_as_read.label"]}
                                </Typography>
                        }
                    </Grid>
                    <Grid item xs={2} pl={0} display="flex" justifyContent="center">
                        <CircleIcon
                            fill={notification?.isRead ? secondary.main : primary.main}
                            sx={{ fontSize: "0.75rem" }} />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" fontWeight="bold">
                            #{notification?.summaryHeader}
                        </Typography>
                        <Typography variant="subtitle2">
                            {notification?.summaryBody?.substring(notification?.summaryBody?.indexOf("Dear"))}
                        </Typography>
                        <Typography variant="subtitle2" mt={2}>
                            {/* Extracting only the time */}
                            {FormatDateTimeStamp(notification?.summaryBody.substring(0, notification?.summaryBody?.indexOf("[Asia")))}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} my={2}>
                        <Divider />
                    </Grid>
                </Grid>
            ))}
            {showLoading &&
                <Typography textAlign="center" variant="subtitle1">
                    {languagePack?.["notification.notification.notification_overlay_popup.loading.label"]}
                </Typography>
            }
        </Box>
    );
}

Notification.propTypes = {
    closePopup: propTypes.func
};