import { AMBER, BLACK_WHITE, BLUE, LIGHT_BLUE, LIGHT_GREEN, RED } from './colors';

const palette = {
    primary: {
        main: LIGHT_GREEN[500],
        contrastText: BLACK_WHITE[50],
    },
    primaryLight: {
        main: BLACK_WHITE[50],
        contrastText: BLACK_WHITE[900],
    },
    primaryCheckIcon: {
        main: LIGHT_GREEN[1000],
        contrastText: BLACK_WHITE[50],
    },
    secondary: {
        main: BLACK_WHITE[400],
        contrastText: BLACK_WHITE[900],
    },
    error: {
        main: RED[400]
    },
    info: {
        main: AMBER[100]
    },
    transparent: {
        main: "transparent",
        contrastText: BLACK_WHITE[600],
    },
    transparentIcon: {
        main: BLACK_WHITE[600],
    },
    secondaryBtn: {
        main: LIGHT_GREEN[50],
        contrastText: LIGHT_GREEN[500],
    },
    blueBtn: {
        main: BLUE[800],
        contrastText: BLACK_WHITE[50],
    },
    disabledCheckbox: {
        main: BLACK_WHITE[700]
    },
    emerald: {
        50: LIGHT_GREEN[50],
        100: LIGHT_GREEN[100],
        200: LIGHT_GREEN[200],
        300: LIGHT_GREEN[300],
        400: LIGHT_GREEN[400],
        500: LIGHT_GREEN[500],
        600: LIGHT_GREEN[600],
        700: LIGHT_GREEN[700],
        800: LIGHT_GREEN[800],
        900: LIGHT_GREEN[900],
        950: LIGHT_GREEN[950]
    },
    neutral: {
        10: BLACK_WHITE[10],
        30: BLACK_WHITE[30],
        50: BLACK_WHITE[50],
        100: BLACK_WHITE[100],
        200: BLACK_WHITE[200],
        300: BLACK_WHITE[300],
        400: BLACK_WHITE[400],
        500: BLACK_WHITE[500],
        600: BLACK_WHITE[600],
        700: BLACK_WHITE[700],
        800: BLACK_WHITE[800],
        900: BLACK_WHITE[900]
    },
    lightBlue:{
        50: LIGHT_BLUE[50],
        100: LIGHT_BLUE[100],
        200: LIGHT_BLUE[200],
        300: LIGHT_BLUE[300],
        400: LIGHT_BLUE[400],
        500: LIGHT_BLUE[500],
        600: LIGHT_BLUE[600],
        700: LIGHT_BLUE[700],
        800: LIGHT_BLUE[800],
        900: LIGHT_BLUE[900],
        950: LIGHT_BLUE[950]
    },
    blue:{
        50: BLUE[50],
        100: BLUE[100],
        200: BLUE[200],
        300: BLUE[300],
        400: BLUE[400],
        500: BLUE[500],
        600: BLUE[600],
        700: BLUE[700],
        800: BLUE[800],
        900: BLUE[900],
        950: BLUE[950]
    },
    red:{
        100: RED[100],
        400: RED[400],
        500: RED[500]
    },
    amber:{
        100: AMBER[100],
        600: AMBER[600]
    },
    gradient:{
        main: `linear-gradient(90deg, ${LIGHT_GREEN[950]} 0%, ${BLUE[950]} 112.4%)`,
        closure_card:`linear-gradient(90deg, ${BLUE[950]} 0%, ${BLUE[800]} 100%)`
    },
    background: {
        default: BLACK_WHITE[300]
      }
}

export default palette; 