const ENDPOINT = {
    SSE: "/api/SSEvents/getMessage",
    UPLOAD_CLAIM_OUTCOME: "/api/claim/outcome/upload",

    AUTH_SIGNUP_PREAUTH: "/platform/v1/auth/signup/pre-auth",
    AUTH_SIGNUP_TOKEN_PRE_GRANT:"/platform/v1/auth/signup/token/pre-grant",
    AUTH_SIGNUP_TOKEN_GRANT: "/platform/v1/auth/signup/token/grant",
    AUTH_SIGNUP_TOKEN_CANCEL: "/platform/v1/auth/signup/token/cancel",
    AUTH_LOGIN_PREAUTH: "/platform/v1/auth/login/pre-auth",
    AUTH_LOGIN_TOKEN_GRANT: "/platform/v1/auth/login/token/grant",

    DEPENDENT_SIGNUP_PREAUTH: "/platform/v1/auth/dependent/signup/pre-auth",
    DEPENDENT_SIGNUP_SYNC: "/platform/v1/auth/dependent/signup/sync",
    DEPENDENT_SIGNUP_TOKEN_GRANT: "/platform/v1/auth/dependent/signup/token/grant",
    DEPENDENT_SIGNUP_PRE_GRANT: "/platform/v1/auth/dependent/signup/pre-grant",

    INBOX_MESSAGE_INQUIRY: "/platform/v1/inbox/message/inquiry",
    INBOX_MESSAGE_STATUS_UPDATE: "/platform/v1/inbox/message/status/update",
    INBOX_MESSAGE_METADATA_INQUIRY: "/platform/v1/inbox/message/metadata/inquiry",
    NOTIFICATION_PREFERENCE_UPDATE: "/platform/v1/profile/preference/notification/update/confirmation",
    NOTIFICATION_PREFERENCE_INQUIRY: "/platform/v1/profile/preference/notification/inquiry",
    PRELOGIN_BUCKET: "/prelogin/config/prelogin.json",

    LOGIN_POLICY_PREAUTH: "/platform/v1/auth/login/policy/pre-auth",
    PROFILE_MYINFO_PREAUTH: "/platform/v1/auth/idp/profile/myinfo/pre-auth",
    PROFILE_MYINFO_PREGRANT: "/platform/v1/auth/idp/profile/myinfo/pre-grant",

    AUTH_TOKEN_REVOKE: "/platform/v1/auth/token/revoke",

    CONSENT_REVOKE: "/platform/v1/profile/revocation/submission",
    CONSENT_REVOKE_PRECHECK: "/platform/v1/consent/revocation/pre-check",

    PROFILE_DEPENDENT_INQUIRY: "/platform/v1/profile/dependent/inquiry",
    VIEW_INSURANCE_COVERAGE: "/platform/v1/consent/policy/inquiry",
    CASE_LIST_INQUIRY: "/platform/v1/case/list/inquiry",
    CASE_DETAIL_INQUIRY: "/platform/v1/case/detail/inquiry",
    CASE_POLICY_INQUIRY: "/platform/v1/case/policy/inquiry",
    CASE_POLICY_PREFERENCE_SUBMIT: "/platform/v1/case/policy-preference/submission",

    POLICY_RESYNC_PRE_CHECK: "/platform/v1/consent/policy/resync/pre-check",
    CASE_POLICY_RESYNC: "/platform/v1/case/policy/resync",
    CASE_POLICY_DEPENDENT_RESYNC: "/platform/v1/case/policy/dependent/resync",

    CONSENT_POLICY_RESYNC: "/platform/v1/consent/policy/resync",
    CONSENT_POLICY_DEPENDENT_RESYNC: "/platform/v1/consent/policy/dependent/resync",

    PROFILE__MYINFO_CANCEL: "/platform/v1/auth/idp/profile/myinfo/cancel",
    SIGNUP_TOKEN_CANCEL: "/platform/v1/auth/dependent/signup/token/cancel"
};

export default ENDPOINT;