import React from 'react';
import propTypes from "prop-types";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { useNavigate, useRouteError } from "react-router-dom";
import styled from "@emotion/styled";
import FullScreenBox from '../components/StyledComponents/FullScreen';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { ApplicationContext } from '../recoil/atoms/ApplicationContext';
import Footer from '../components/layout/Footer';
import FE_URL from '../constants/urls';
import { User } from '../recoil/atoms/User';
import secureLocalStorage from 'react-secure-storage';


const BlueGrid = styled(Grid)(({ theme }) => ({
    background: theme.palette.neutral[300],
    minHeight: "inherit"
}));

/**
 * 
 * @param {error} param0 - comes from DOM, Lifecycle errors from react-boundary-error
 * @returns error page
 */
export default function Error({ error }) {
    const routeError = useRouteError(); // coming from route errors
    let errorCalculated = error || routeError;
    const { languagePack } = useRecoilValue(ApplicationContext);
    const navigate = useNavigate();
    const resetUserRecoil = useResetRecoilState(User);

    const refresh = () => {
        window.location.reload(false);
    };

    if (!errorCalculated) {
        errorCalculated = {
            title: languagePack?.["error.page_load_failure.title.label"],
            message: languagePack?.["error.page_load_failure.description.label"],
            ctaText: languagePack?.["error.page_load_failure.refresh.button"],
            cta: refresh
        };
    }

    // let errorCalculated = error || routeError;
    if (errorCalculated.status === 404) {
        errorCalculated = {
            title: errorCalculated.statusText,
            message: errorCalculated.data,
            cta: errorCalculated.cta
        };
    } else if (errorCalculated.status === 403) {
        errorCalculated = {
            title: languagePack?.[`error.session_timeout.title.label`] ?? "Session timeout",
            message: languagePack?.[`error.session_timeout.description.label`] ?? "You have been logged out due to inactivity. Please sign in again to continue.",
            ctaText: "Login",
            cta: () => {
                secureLocalStorage.clear();
                resetUserRecoil();
                navigate(FE_URL.GETTING_STARTED, { replace: true });
            }
        };
    }

    return (
        <>
            <FullScreenBox disableoverflowy>
                <BlueGrid
                    container
                    gap={1}
                    p={4}
                    alignContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h2" color="error">
                            :(
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="error">
                            Error
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="neutral.30">
                            {errorCalculated?.title?.toString()?.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="p" color="neutral.10">
                            {errorCalculated?.message}
                        </Typography>
                    </Grid>
                    {
                        errorCalculated.ctaText &&
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={errorCalculated.cta}
                                fullWidth>
                                {errorCalculated?.ctaText}
                            </Button>
                        </Grid>
                    }
                </BlueGrid>
            </FullScreenBox>
            <Footer />
        </>
    );
}

Error.propTypes = {
    error: propTypes.object
};
