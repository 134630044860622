import { BLACK_WHITE } from "../pallete/colors";

const MuiSnackbarContent = {
    styleOverrides: {
        root: {
            background: BLACK_WHITE[700],
            justifyContent: "center"
        }
    }
};


export default MuiSnackbarContent;