import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import propTypes from "prop-types";

export default function CircleIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill={props.fill} />
            </svg>

        </SvgIcon>
    );
}

CircleIcon.propTypes = {
    props: propTypes.object,
    fill: propTypes.string
};