import getAxiosInstance from "../utils/axios/axios";
import getAxiosInstanceNoAuth from "../utils/axios/axiosNoAuth";

export default function apiCall(url, requestBody, headers) {
    return getAxiosInstance().post(
        url,
        requestBody,
        {
            headers
        }
    )
        .then(resp => resp)
        .catch(error => {
            throw error;
        });
}

export function noAuthApiCall(url, requestBody, headers) {
    return getAxiosInstanceNoAuth().post(
        url,
        requestBody,
        {
            headers
        }
    )
        .then(resp => resp)
        .catch(error => {
            throw error;
        });
}