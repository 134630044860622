const typography = {
  fontFamily: ['Barlow', 'sans-serif'].join(","),
  h1: {
    fontFamily: ["BarlowBold"].join(","),
  },
  h2: {
    fontFamily: ["BarlowBold"].join(","),
  },
  h3: {
    fontFamily: ["BarlowBold"].join(","),
  },
  h4: {
    fontFamily: ["BarlowBold"].join(","),
  },
  h5: {
    fontFamily: ["BarlowBold"].join(","),
  },
  h6: {
    fontFamily: ["BarlowBold"].join(","),
  },
  subtitle1: {
    fontFamily: ["Barlow"].join(",")
  },
  subtitle2: {
    fontFamily: ["Barlow"].join(",")
  },
  body1: {
    fontFamily: ["Barlow"].join(",")
  },
  body2: {
    fontFamily: ["Barlow"].join(",")
  },
  button:{
    textTransform: "capitalize"
  }
}

export default typography;