import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import secureLocalStorage from "react-secure-storage";
import APPLICATION_CONSTANTS from "../../constants/constants";
import { AxiosNoAUthBaseUrlConfig } from "../../pages/Config";

/***
 * Axios Instance when Authentication is not required.
 */

let axiosInstanceNoAuth = null;

export const createAxiosInstanceNoAuth = () => {
    axiosInstanceNoAuth = axios.create({
        baseURL: AxiosNoAUthBaseUrlConfig().trim(),
        headers: {
            "Accept-Language": APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0),
            "X-Client-Version": APPLICATION_CONSTANTS.REACT_APP_VERSION,
            "X-Channel-ID": APPLICATION_CONSTANTS.X_CHANNEL_ID
        }
    });

    attachInterceptor(axiosInstanceNoAuth);
};

/**
 * Intercepts Request to add custom headers
 * @param {axiosInstance} axiosInstance 
 */
const attachInterceptor = (axiosInstanceNoAuth) => {
    axiosInstanceNoAuth.interceptors.request.use(async function (config) {
        config.headers.Authorization = secureLocalStorage.getItem(APPLICATION_CONSTANTS.JWT_TOKEN);
        config.headers["X-Correlation-ID"] = `${uuidv4()}-crid`;
        return config;
    });
};

/**
 * 
 * @returns current AxiosInstance for API that needs Authorizer
 */
const getAxiosInstanceNoAuth = () => axiosInstanceNoAuth;

export default getAxiosInstanceNoAuth;