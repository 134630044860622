import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '../../assets/icons/Menu.svg';
import CloseIcon from '../../assets/icons/Close.svg';
import NotificationsOutlinedIcon from '../../assets/icons/Bell.svg';
import Logo from "../../assets/images/HiipLogo.svg";
import { useParams } from 'react-router-dom';
import IdleTimeout from './idleTimeout';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import MenuPopup from './MenuPopUp';
import Notification from './NotificationPopup';
import apiCall from '../../services';
import ENDPOINT from '../../constants/endpoints';
import { useRecoilValue } from 'recoil';
import { User } from '../../recoil/atoms/User';
import { useErrorBoundary } from 'react-error-boundary';

const POLL_TIME = 30000; // 30 seconds

export default function Header() {
    const [anchorElement, setAnchorElement] = useState(null);
    const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
    const { jwt } = useRecoilValue(User);
    const { showBoundary } = useErrorBoundary();

    const handleMenuClick = (event) => {
        setAnchorElement(event?.target?.name ? event?.currentTarget : undefined);
    };

    const open = Boolean(anchorElement);
    const id = open ? anchorElement?.name : undefined;

    const { dependant } = useParams();

    useEffect(() => {
        let intervalId;
        if (jwt) {
            intervalId = setInterval(() => {
                apiCall(
                    ENDPOINT.INBOX_MESSAGE_METADATA_INQUIRY
                ).then(({ data }) => {
                    setHasUnreadNotification(data.hasUnread);
                }).catch(({ response }) => {
                    if (response.status === 403)
                        showBoundary({
                            ...response
                        });
                });
            }, POLL_TIME);
        } else if (intervalId) {
            clearInterval(intervalId);
        }
        return () => { clearInterval(intervalId); };
    }, [jwt]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="sticky"
                top={0}
                component="nav"
                color="primaryLight"
            >
                <Toolbar sx={{ height: "4rem" }}>
                    {jwt &&
                        <IconButton
                            name="menu"
                            onClick={handleMenuClick} >
                            {anchorElement?.name === "menu" ?
                                <img
                                    src={CloseIcon}
                                    alt="close"
                                    name="close" />
                                :
                                <img
                                    src={MenuIcon}
                                    alt="Menu"
                                    name="menu" />
                            }
                        </IconButton>
                    }
                    <Box
                        justifyContent="center"
                        sx={{
                            flexGrow: 1,
                            display: "flex"
                        }}>
                        <img
                            src={Logo}
                            alt="logo"
                            height={40}
                            width={46}
                        />
                    </Box>
                    {jwt &&
                        <Box
                            textAlign="end">
                            <IconButton
                                name="notification"
                                size="large"
                                aria-label="Notification"
                                onClick={handleMenuClick} >
                                {anchorElement?.name === "notification" ?
                                    <img
                                        src={CloseIcon}
                                        alt="close"
                                        name="close"
                                    />
                                    :
                                    <>
                                        {hasUnreadNotification ?
                                            <Badge variant="dot" color="error">
                                                <img
                                                    src={NotificationsOutlinedIcon}
                                                    alt="Notification"
                                                    name="notification" />
                                            </Badge>
                                            :
                                            <img
                                                src={NotificationsOutlinedIcon}
                                                alt="Notification"
                                                name="notification" />
                                        }
                                    </>
                                }
                            </IconButton>
                        </Box>
                    }
                </Toolbar>
            </AppBar>
            {
                anchorElement &&
                <Popover
                    sx={{ padding: 1 }}
                    id={id}
                    open={open}
                    onClose={handleMenuClick}
                    anchorEl={anchorElement}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: anchorElement?.name?.includes("menu")
                            ? "left"
                            : anchorElement?.name?.includes("notification")
                                ? "right"
                                : "left",
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: anchorElement?.name?.includes("menu")
                            ? "left"
                            : anchorElement?.name?.includes("notification")
                                ? "right"
                                : "left",
                    }}
                    slotProps={{
                        paper: {
                            sx(theme) {
                                return {
                                    [theme.breakpoints.down("lg")]: {
                                        width: "80%",
                                        height: "70%",
                                        top: `${theme.spacing(8)} !important`,
                                        left: anchorElement?.name?.includes("menu")
                                            ? "0 !important"
                                            : "revert !important",
                                        right: anchorElement?.name?.includes("notification")
                                            ? "0 !important"
                                            : "",
                                        borderRadius: 0
                                    },
                                };
                            },
                        },
                    }}
                >
                    {
                        anchorElement?.name === "menu"
                            ? <MenuPopup
                                dependant={dependant}
                                closePopup={handleMenuClick} />
                            : <Notification closePopup={handleMenuClick} />
                    }
                </Popover>
            }
            <div id="detail">
                {jwt &&
                    <>
                        < IdleTimeout />
                    </>
                }
            </div>
        </Box >
    );
}