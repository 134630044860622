const APPLICATION_CONSTANTS = {
    JWT_TOKEN: "jwt_token",
    REFRESH_TOKEN: "refresh_token",
    JWT_EXPIRES_AT: "jwt_token_expiration_time",
    REQUESTED_FOR: "requested_for",
    PARENT_PROFILE_REF_ID: "parent_profile_ref_id",
    DEPENDENT_PROFILE_REF_ID: "dependent_profile_ref_id",
    AUTH_REF_ID: "auth_ref_id",
    CASE_METADATA_LINEAR_ID: "caseMetadataLinearId",
    POLICY_PRECHECK_SCREEN:"policy_precheck_screen",
    VIEW_INSURANCE_SCREEN:"view_insurance_screen",
    CASE_DETAILS_SCREEN:"case_details_screen",
    IS_RESYNC:"is_resync",
    IS_RESYNC_START:"is_resync_start",
    
    ACCEPTABLE_IDLE_TIME: 10 * 60 * 1000, // 10 mins
    EXPIRY_TIME_OFFSET: 4 * 60 * 1000, // 4 mins *in seconds*
    PATIENT_ONBOARDING_STEP: {
        CONSENT: "consent",
        INFORMATION_RETRIEVAL: "information-retrieval",
        VIEW_INSURANCE_COVERAGE: "view-insurance-coverage",
        NOTIFICATION_PREFERENCE: "setup-notification-preference"
    },
    APPLICATION_LANGUAGES: ["en-SG"],
    LANGUAGE_PACK_INTERVAL: 30 * 60 * 1000, // 10 mins
    TOASTER_TIMEOUT: 3000,
    HEADER_HEIGHT:75, // px
    FOOTER_HEIGHT: 100, // px
    ONBOARDING_STEPPER_HEIGHT: 108, // px
    STICKY_BUTTON_HEIGHT: 96, // px
    OFFSET_HEIGHT: 30,
    DEPENDENT_CARD_TYPE: {
        MY_INFO_CHILD_LISTING: "MY_INFO_CHILD_LISTING",
        CHILD_LISTING_PAGE: "CHILD_LISTING_PAGE"
    },
    TOKEN_TYPE:{
        REFRESH_TOKEN: "refreshToken",
        TOKEN: "token"
    },
    NA: "-",
    NUMBER_OF_ROWS: 20,
    CASE_STATUS_MAPPING:{
        SUBMIT_TO_DO: "SUBMIT_TO_DO",
        SUBMITTED_RANK_TO_DO:"SUBMITTED_RANK_TO_DO",
        UPDATED_RANK_TO_DO:"UPDATED_RANK_TO_DO",
        AUTH_TO_DO:"AUTH_TO_DO",
        AUTH_IN_PROGRESS:"AUTH_IN_PROGRESS",
        AUTH_DONE:"AUTH_DONE",
        CASE_VOIDED:"CASE_VOIDED"
    },
    ADMISSION_STATUS_MAPPING:{
        NOT_ADMITTED: "NOT_ADMITTED",
        ADMITTED:"ADMITTED",
        DISCHARGED:"DISCHARGED",
    },
    X_CHANNEL_ID: "COMMON_SERVICE_WEB"
}

export default APPLICATION_CONSTANTS