import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from "recoil";
import { ApplicationContext } from "../../recoil/atoms/ApplicationContext";
import { useNavigate } from "react-router-dom";
import APPLICATION_CONSTANTS from "../../constants/constants";

const Neutral300Box = styled(Box)(({theme}) => ({
    background: theme.palette.gradient.main,
}));

export default function Footer() {
    const componentName = "footer";
    const [footers, setFooters] = useState([]);
    const { footer, languagePack } = useRecoilValue(ApplicationContext);
    const navigate = useNavigate();

    useEffect(() => {        
        setFooters([
            {
                id: "privacy-policy",
                label: languagePack?.[`${componentName}.privacy_policy.link`]
            },
            {
                id: "faq",
                label: languagePack?.[`${componentName}.faq.link`]
            },
            {
                id: "terms-of-use",
                label: languagePack?.[`${componentName}.tnc.link`]
            },
            {
                id: "feedback",
                label: languagePack?.[`${componentName}.feedback.link`]
            }
        ]);
    }, [languagePack]);

    const navigateToSurveyForm = () => { window.location.href = footer.feedbackUrl; }

    return (
        <Neutral300Box
            position="fixed"
            bottom={0}
            width={"100%"}
            height={`${APPLICATION_CONSTANTS.FOOTER_HEIGHT}px`}>
            <Divider/>
            <Stack direction="row" justifyContent="space-between" p={2}>
                {footers.map(({ id, label }) => (
                    <Link
                        key={id}
                        variant="caption"
                        color="neutral.50" 
                        onClick={() => id === 'feedback' ? navigateToSurveyForm() : navigate(`/${id}`)}>
                        {label}
                    </Link>
                ))}
            </Stack>
            <Stack direction="row" justifyContent="space-around">
                <Typography variant="caption" color="neutral.50">
                    {languagePack?.[`${componentName}.copyright.link`]}
                </Typography>
            </Stack>
        </Neutral300Box>
    );
}
