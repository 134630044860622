import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import styled from "@emotion/styled";

const BoxWrapper = styled(Stack)(() => ({
    width: '100%',
    height: '100vh',
}));

function Loader() {
    return (
        <BoxWrapper alignItems="center" justifyContent="center">
            <CircularProgress disableShrink />
        </BoxWrapper>
    );
}

export default Loader;