import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { v4 as uuidv4 } from 'uuid';
import APPLICATION_CONSTANTS from "../../constants/constants";
import { noAuthApiCall } from "../../services";
import ENDPOINT from "../../constants/endpoints";
import { AxiosBaseUrlConfig } from "../../pages/Config";

/***
 * Axios Instance when Authentication is required.
 */
let axiosInstance = null;

export const createAxiosInstance = () => {
    axiosInstance = axios.create({
        baseURL: AxiosBaseUrlConfig().trim(),
        headers: {
            "Accept-Language": APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0),
            "X-Client-Version": APPLICATION_CONSTANTS.REACT_APP_VERSION,
            "X-Channel-ID": APPLICATION_CONSTANTS.X_CHANNEL_ID
        }
    });

    attachInterceptor(axiosInstance);
};

/**
 * generates new accessToken 
 */
export async function fetchRefreshToken() {
    const xRequestedFor = secureLocalStorage.getItem(APPLICATION_CONSTANTS.REQUESTED_FOR);
    const refreshToken = secureLocalStorage.getItem(APPLICATION_CONSTANTS.REFRESH_TOKEN);
    const jwtTokenExpiration = new Date(secureLocalStorage.getItem(APPLICATION_CONSTANTS.JWT_EXPIRES_AT));
    if (xRequestedFor) {
        if (new Date().getTime() >= new Date(jwtTokenExpiration.getTime() - APPLICATION_CONSTANTS.EXPIRY_TIME_OFFSET).getTime()) {
            noAuthApiCall(ENDPOINT.AUTH_LOGIN_TOKEN_GRANT, {
                refreshToken,
                grantType: APPLICATION_CONSTANTS.TOKEN_TYPE.REFRESH_TOKEN
            })
                .then(({ data }) => {
                    secureLocalStorage.setItem(APPLICATION_CONSTANTS.JWT_TOKEN, data.accessToken);
                    secureLocalStorage.setItem(APPLICATION_CONSTANTS.REFRESH_TOKEN, data.refreshToken);
                    secureLocalStorage.setItem(APPLICATION_CONSTANTS.JWT_EXPIRES_AT, new Date(Date.now() + data.expiresIn * 1000));
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }
}

/**
 * Intercepts Request to add authorization access token and custom headers
 * @param {axiosInstance} axiosInstance 
 */
const attachInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use(async function (config) {
        try {
            await fetchRefreshToken();
            config.headers.Authorization = secureLocalStorage.getItem(APPLICATION_CONSTANTS.JWT_TOKEN);
            config.headers["X-Correlation-ID"] = `${uuidv4()}-crid`;
            config.headers["X-Requested-For"] = secureLocalStorage.getItem(APPLICATION_CONSTANTS.REQUESTED_FOR);
            return config;

        } catch (error) {
            console.error(`Error in axios interceptor - ${error}`);
        }
    });
};

/**
 * 
 * @returns current AxiosInstance for API that needs Authorizer
 */
const getAxiosInstance = () => axiosInstance;

export default getAxiosInstance;
