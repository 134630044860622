import { AMBER, BLACK_WHITE, LIGHT_GREEN, RED } from "../pallete/colors"
const MuiChip = {
    styleOverrides: {
      colorPrimary: {
        color: LIGHT_GREEN[400],
        background: LIGHT_GREEN[50]
      },
      colorSuccess: {
        color: BLACK_WHITE[50],
        background: LIGHT_GREEN[400]
      },
      colorWarning: {
        color: AMBER[600],
        background: AMBER[100]
      },
      colorError: {
        color:  RED[500],
        background: RED[100]
      },
      colorSecondary: {
        color:  BLACK_WHITE[600],
        background: BLACK_WHITE[300]
      },
    }
  }

export default MuiChip