import { DateTime } from "luxon";
import APPLICATION_CONSTANTS from "../constants/constants";

export function FormatDateTimeStamp(date) {
    return DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat('dd MMM yyyy, HH:mm') : APPLICATION_CONSTANTS.NA;

}

export const FormatDate = (date) =>
    DateTime.fromISO(date).isValid ? DateTime.fromISO(date).toFormat('dd MMM yyyy') : APPLICATION_CONSTANTS.NA;


export function FormatDateYDM(date) {
    if (date) {
        const year = date.toLocaleString('default', { year: 'numeric' });
        const month = date.toLocaleString('default', { month: '2-digit' });
        const day = date.toLocaleString('default', { day: '2-digit' });

        return [year, month, day].join('-');
    }
}
