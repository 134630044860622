"use client";
import './App.css';
import React, { Suspense, useEffect, useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import createCache from '@emotion/cache';

import theme from './theme';
import Error from './pages/Error';
import routeConfig from './Routes';
import { useSetRecoilState } from 'recoil';
import { ApplicationContext } from './recoil/atoms/ApplicationContext';
import getLanguagePack from './utils/getPreloginBucketDetails';
import Loader from './components/layout/Loader';
import { createAxiosInstance } from './utils/axios/axios';
import { createAxiosInstanceNoAuth } from './utils/axios/axiosNoAuth';
import { CSPConfig } from './pages/Config';
import { Helmet } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';

// create Emotion cache with nonce
const createEmotionCache = (nonce) => createCache({
  key: 'mui',
  nonce
});

function App() {
  const router = createBrowserRouter(routeConfig);
  const setApplicationContext = useSetRecoilState(ApplicationContext);
  const [nonce,] = useState("MY_NONCE");

  const cache = createEmotionCache(nonce);

  useEffect(() => {
    getLanguagePack(setApplicationContext);
    createAxiosInstance();
    createAxiosInstanceNoAuth();
  }, []);

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={CSPConfig()}
        ></meta>
      </Helmet>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={Error}>
            <Suspense fallback={<Loader />}>
              <RouterProvider router={router} />
            </Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default App;
