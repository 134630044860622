// csp config
export function CSPConfig() {
    const csp = " script-src 'self' 'nonce-MY_NONCE'; " +
        " img-src 'self'; " +
        " font-src 'self'; " +
        "connect-src 'self' https://csn-001-sit-hiip-bucket.s3.ap-southeast-1.amazonaws.com " + process.env.REACT_APP_API_NOAUTH + " " + process.env.REACT_APP_API_AUTH + " " + " ; " +
        " style-src 'self' 'nonce-MY_NONCE'; " +
        " media-src 'self'; " +
        " object-src 'none'; ";

    return csp;
}

// axios base url

export function AxiosBaseUrlConfig() {
    return process.env.REACT_APP_API_AUTH;
}

export function AxiosNoAUthBaseUrlConfig() {
    return process.env.REACT_APP_API_NOAUTH;
}

export function GetPreloginBucketEndpoint() {
    return "https://csn-001-sit-hiip-bucket.s3.ap-southeast-1.amazonaws.com"
}