const FE_URL = {
    LANDING_PAGE: "/",
    ONBOARDING: "/onboarding",
    ONBOARDING_SUCCESS: "/onboarding-success",
    HOME: "/home",
    MANAGE_CHILDREN: "/manage/children",
    MANAGE_NOTIFICATIONS: "/manage/notifications",
    MANAGE_CONSENT: "/manage/consent",
    GETTING_STARTED: "/getting-started",
    GETTING_STARTED_CHILDREN: "/getting-started/child",
    VIEW_CASES: "/case/listing",
    VIEW_INSURANCE_COVERAGE: "/view/insurance-coverage",
    DEPENDENT_DETAILS: "/children",
    DEPENDENT_INFO_RETRIEVAL:"/information-retrieval/child",
    CASE_DETAILS: "/case/details",
    PAYOR_SCHEME_RANKING: "/payor-scheme-ranking",
    SYNC_INSURANCE_POLICIES: "/sync-insurance-policies",
    POLICY_PREFERENCE_SUCCESS: "/policy-preference-success",
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_AND_CONDITION: "/terms-of-use",
    FAQ: "/faq",
    FEEDBACK:"/feedback",
    SINGPASS_SIGNUP_CALLBACK_URL: "/callback"

}
export default FE_URL