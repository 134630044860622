import React from 'react';
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import secureLocalStorage from 'react-secure-storage';
import propTypes from "prop-types"
import FE_URL from '../constants/urls';
import APPLICATION_CONSTANTS from '../constants/constants';
import { User } from '../recoil/atoms/User';

function PrivateRoute({ children }) {
  const { jwt } = useRecoilValue(User);
  const accesstoken = secureLocalStorage.getItem(APPLICATION_CONSTANTS.JWT_TOKEN);
  if (jwt || accesstoken) {
    return children;
  } else {
    secureLocalStorage.clear()
    return <Navigate to={FE_URL.LANDING_PAGE} replace />;
  }
}

PrivateRoute.propTypes ={
  children: propTypes.object
}
export default PrivateRoute;