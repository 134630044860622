import secureLocalStorage from "react-secure-storage";
import { atom } from "recoil";
import APPLICATION_CONSTANTS from "../../constants/constants";

const initUserAtom = {
    name: null,
    email: null,
    lastLogin: null,
    profileImage: null,
    profileRefId: null,
    lastUIInteraction: Date.now(),
    jwt: secureLocalStorage.getItem(APPLICATION_CONSTANTS.JWT_TOKEN)
}

export const User  = atom({
    key: "User Atom",
    default: initUserAtom,
})
