import React from "react";
import propTypes from "prop-types";
import Box from "@mui/material/Box";
export default function FullScreenBox({
    children,
    heightoffset,
    display,
    disableoverflowy,
    position,
    className,
}) {

    return (
        <Box
            sx={{
                maxHeight: `calc(100vh - ${heightoffset ?? "0px"})`,
                minHeight: `calc(100vh - ${heightoffset ?? "0px"})`,
                height: `calc(100vh - ${heightoffset ?? "0px"})`,
                overflowY: disableoverflowy ? "unset" : "scroll",
                display,
                position,
            }}
            className={className}
        >
            {children}
        </Box>
    );
}

FullScreenBox.propTypes = {
    children: propTypes.any,
    heightoffset: propTypes.string,
    display: propTypes.string,
    disableoverflowy: propTypes.bool,
    position: propTypes.string,
    className: propTypes.string
};