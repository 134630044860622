import { atom } from "recoil";
import APPLICATION_CONSTANTS from "../../constants/constants";

const initAppContext = {
    language: APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0).toLowerCase(),
    footer:{},
    languagePack:undefined,
}

export const ApplicationContext = atom({
    key: "Application Atom",
    default: initAppContext
})