import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { ApplicationContext } from "../../recoil/atoms/ApplicationContext";
import handleLogOut from "../../utils/handleLogout";
import { User } from "../../recoil/atoms/User";
import propTypes from "prop-types";
import FE_URL from "../../constants/urls";
import secureLocalStorage from "react-secure-storage";
import APPLICATION_CONSTANTS from "../../constants/constants";


export default function MenuPopup({ closePopup, dependant }) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { languagePack } = useRecoilValue(ApplicationContext);
    const resetUserRecoil = useResetRecoilState(User);
    const component = "menu_overlay";
    const [menu, setMenu] = useState([]);
    const menuOptions = [
        {
            id: 1,
            label: languagePack?.[`${component}.home.label`],
            navigateTo: secureLocalStorage.getItem(APPLICATION_CONSTANTS.DEPENDENT_PROFILE_REF_ID)
                ? `${FE_URL.HOME}/children`
                : FE_URL.HOME,
        },
        {
            id: 2,
            label: languagePack?.[`${component}.view_all_cases.label`],
            navigateTo: dependant?.includes("child") ? `${FE_URL.VIEW_CASES}/children` : FE_URL.VIEW_CASES,
        },
        {
            id: 4,
            label: languagePack?.[`${component}.manage_children.label`],
            navigateTo: FE_URL.MANAGE_CHILDREN,
        },
        {
            id: 9,
            label: languagePack?.["home.quick_links.return_to_parent_home.button"],
            navigateTo: `${FE_URL.HOME}`
        },
        {
            id: 5,
            label: languagePack?.[`${component}.view_insurance_coverage.label`],
            navigateTo: dependant?.includes("child") ? `${FE_URL.VIEW_INSURANCE_COVERAGE}/children` : FE_URL.VIEW_INSURANCE_COVERAGE,
        },
        {
            id: 6,
            label: languagePack?.[`${component}.manage_notification_preference.label`],
            navigateTo: dependant?.includes("child") ? `${FE_URL.MANAGE_NOTIFICATIONS}/children` : FE_URL.MANAGE_NOTIFICATIONS,
        },
        {
            id: 7,
            label: languagePack?.[`${component}.manage_consent.label`],
            navigateTo: dependant?.includes("child") ? `${FE_URL.MANAGE_CONSENT}/children` : FE_URL.MANAGE_CONSENT,
        },
        {
            id: 8,
            label: languagePack?.[`${component}.logout.label`],
            navigateTo: "",
        }
    ];

    const handleLogout = () => {
        closePopup();
        handleLogOut(navigate, resetUserRecoil);
    };
    const handleNavigation = (id, navigateTo) => {
        closePopup();
        if (id === 9) {
            const parentProfileRefId = secureLocalStorage.getItem(APPLICATION_CONSTANTS.PARENT_PROFILE_REF_ID);
            secureLocalStorage.setItem(APPLICATION_CONSTANTS.REQUESTED_FOR, parentProfileRefId);
            secureLocalStorage.removeItem(APPLICATION_CONSTANTS.DEPENDENT_PROFILE_REF_ID);
            secureLocalStorage.setItem("isChild",false)
        }
        navigate(navigateTo);
    };

    useEffect(() => {
        if (secureLocalStorage.getItem(APPLICATION_CONSTANTS.DEPENDENT_PROFILE_REF_ID))
            // removed "manage children" option when in child profile
            setMenu(menuOptions.filter(mo => mo.id !== 4));
        else
            // removed "back to parent" option when in parent profile
            setMenu(menuOptions.filter(mo => mo.id !== 9));
    }, []);
    return (
        <Box>
            {menu.map(({ id, label, navigateTo }) => (
                id === 8 ?
                    <Typography
                        key={id}
                        p={2.5}
                        variant="h6"
                        color="error"
                        onClick={handleLogout}>
                        {label}
                    </Typography>
                    :
                    <div key={id}>
                        <Typography
                            name={label}
                            p={2.5}
                            variant="h6"
                            color={pathname === navigateTo ? "blue.800" : "neutral.900"}
                            onClick={() => handleNavigation(id, navigateTo)}>
                            {label}
                        </Typography>
                        <Divider />
                    </div>
            ))}
        </Box>
    );
}

MenuPopup.propTypes = {
    closePopup: propTypes.func,
    dependant: propTypes.string
};