import React from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom';
import './index.css';
import App from './App';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UnsecuredPage = () => {
  return (
    <div>
      <div className="redAlert">
        You cant access the portal from here.
      </div>
      <h2>
        Click{' '}
        <Link
          href={window.self.location.href}
          title="Web Application"
          target="blank"
        >
          here
        </Link>{' '}
        to access WebApp safely.
      </h2>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
if (window.self === window.top) {
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </HelmetProvider>
    </React.StrictMode>
  );
} else {
  root.render(<
    UnsecuredPage
  />, document.getElementById(
    'root'
  ));
}
