export const LIGHT_GREEN = {
    50: "#E4F8EC",
    100: "#BDEED1",
    200: "#90E3B3",
    300: "#58D894",
    400: "#1F845B",
    500: "#147951",
    600: "#00B458",
    700: "#005116",
    800: "#00903F",
    900: "#006F2A",
    950: '#01AF70',
    1000: "#00B89D"
  };

  export const LIGHT_BLUE = {
    50: "#E0F4FD",
    100: "#B0E2F9",
    200: "#7BD0F5",
    300: "#43BDF1",
    400: "#00AFEF",
    500: "#00A1EC",
    600: "#0094DE",
    700: "#0081CA",
    800: "#0070B6",
    900: "#005195",
    950: "#2AA3E9"
  };

  export const GREEN = {
    50: "#EAFFF7",
    100: "#C6FFEA",
    200: "#A1FFDD",
    300: "#7CFFD0",
    400: "#58FFC3",
    500: "#2FECA8",
    600: "#13C384",
    700: "#009A63",
    800: "#007149",
    900: "#00482F",
  };

  export const BLUE = {
    50: "#E7EBF2",
    100: "#C2CDE0",
    200: "#9BADCB",
    300: "#758DB5",
    400: "#5674A6",
    500: "#345D99",
    600: "#2D5590",
    700: "#244B85",
    800: "#054FB9",
    900: "#153160",
    950: '#009DD6'
  };

  export const BLACK_WHITE = {
    10: '#000000',
    30: '#231815',
    50: "#FFFFFF",
    100: "#FAFAFA",
    200: "#F5F5F5",
    300: "#F0F0F0",
    400: "#DEDEDE",
    500: "#C2C2C2",
    600: "#979797",
    700: "#818181",
    800: "#606060",
    900: "#3C3C3C"
  };

  export const RED ={
    100: '#FFE0E4',
    400: '#DB3800',
    500: '#FF0022'
  }

  export const AMBER = {
    100: "#FFF3D2",
    600: "#F6B02D"
  }