import { BLUE } from "../pallete/colors";

const MuiTab = {
  styleOverrides: {
    root: {
      minWidth: "50%",
      fontWeight: "bold",
      "&.Mui-selected": {
        color: BLUE[800]
      }
    }
  }
};

export default MuiTab;